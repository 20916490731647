#projekte {

    .projekte {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        transition: all .2s ease-in;

        @include breakpoint(tablet) {
            justify-content: space-between;
            gap: 10px;
        }

        @include breakpoint(laptop) {
            margin-top: 40px;
        }

        .projekt {
            width: 100%;
            margin-bottom: 30px;
            transition: all .2s ease-in-out;

            @include breakpoint(tablet) {
                width: calc(50% - 10px);

                &:nth-child(odd) {
                    transform: translateY(80px);
    
                    @include breakpoint(laptop) {
                        transform: translateY(200px);
                    }
                }
            }

            @include breakpoint(laptop) {
                margin-bottom: 40px;
            }

            @include breakpoint(laptop) {
                width: calc(50% - 10px);
            }

            @include breakpoint(desktop) {
                width: calc(50% - 20px);
            }

            .projekt_open {
                cursor: pointer;

                &:hover {
                    ~ p button:before {
                        left: 8px;
                    }
                }
            }

            img {
                margin-bottom: 10px;
                transition: all .2s ease-in-out;
                width: 100%;
                height: 300px;
                object-fit: cover;

                @include breakpoint(tablet) {
                    margin-bottom: 20px;
                    height: 400px;
                }

                @include breakpoint(laptop) {
                    margin-bottom: 2rem;
                    height: 500px;
                }
            }

            p {
                font-size: 18px;
                font-weight: 600;
                text-align: left;
                padding-left: 4px;

                @include breakpoint(mobile_l) {
                    font-size: 20px;
                }

                @include breakpoint(tablet) {
                    font-size: 18px;
                }

                @include breakpoint(tablet_l) {
                    font-size: 20px;
                }

                @include breakpoint(laptop) {
                    font-size: 24px;
                }

                button {
                    position: relative;
                    padding-left: 30px;

                    &:before {
                        content: '';
                        width: 16px;
                        height: 20px;
                        background: url('../assets/images/icons/arrow-right-solid.svg') no-repeat center center;
                        background-size: contain;
                        position: absolute;
                        left: 3px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        transition: all .2s ease-in-out;
                    }

                    &:hover {
                        &:before {
                            left: 8px;
                        }
                    }
                }
            }
        }
    }
}

#projekt-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $light;
    z-index: 10;
    overflow: scroll;
    padding-top: 160px;
    transition: all .2s ease-in-out;

    @include breakpoint(tablet) {
        padding-top: 200px;
    }

    @include breakpoint(laptop) {
        padding-top: 300px;
    }

    #projekt_close, #projekt_close_bottom {
        display: block;
        font-weight: 400;
        font-size: 16px;
        position: absolute;
        z-index: 10;
        border: 2px solid grey;
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: grey;
        opacity: .6;

        @include breakpoint(tablet) {
            font-size: 20px;
            width: 40px;
            height: 40px;
        }
    
        @include breakpoint(laptop) {
            font-size: 24px;
        }

        &:hover {
            opacity: 1;
        }
    }

    #projekt_close_bottom {
        bottom: 20px;
        left: 20px;
        transition: all .2s ease-in-out;

        @include breakpoint(desktop) {
            left: 0;
        }
    }

    .projekt_nav {
        display: flex;
        flex-direction: row-reverse;
        gap: 14px;
        height: 36px;
        transition: all .2s ease-in;

        @include breakpoint(tablet) {
            height: 40px;
        }

        &.bottom {
            bottom: 20px;
        }

        .swiper-button-next,
        .swiper-button-prev {
            border: 2px solid grey;
            width: 36px;
            height: 36px; 
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: relative;
            opacity: .6;
            margin: 0;
            left: inherit;
            top: inherit;
            right: inherit;
            bottom: inherit;

            @include breakpoint(tablet) {
                width: 40px;
                height: 40px;
            }

            &.swiper-button-disabled {
                opacity: .2;
            }
            
            &:hover {
                opacity: 1;
            }

            &:after {
                color: grey;
                line-height: normal;
                margin-top: -4px;
                font-size: 1.6rem;

                @include breakpoint(tablet) {
                    font-size: 2rem;
                    margin-top: -6px;
                }
            }
        }

        .swiper-button-prev {
            &::after {
                content: '<';
                margin-right: 2px;
            }
        }

        .swiper-button-next {
            &::after {
                content: '>';
                margin-left: 2px;
            }
        }
    }

    .projekt_slider {
        width: 100%;
        overflow: hidden;

        .swiper-slide {
            padding: 40px 0;

            @include breakpoint(tablet) {
                padding: 4rem 0;
            }

            @include breakpoint(laptop) {
                padding: 60px 0 80px;
            }

            .img_container {
                margin: 3rem 0;
                display: flex;
                gap: 20px;
                flex-wrap: wrap;

                @include breakpoint(tablet) {
                    margin: 4rem 0;
                }

                @include breakpoint(laptop) {
                    margin: 6rem 0;
                }

                img {
                    object-fit: cover;
                }

                .half {
                    @include breakpoint(tablet) {
                        width: calc(50% - 10px);
                    }
                }
                .third {
                    @include breakpoint(tablet) {
                        width: calc(42% - 10px);
                    }
                }
                .third_two {
                    @include breakpoint(tablet) {
                        width: calc(58% - 10px);
                    }
                }
                .three-cont {
                    display: flex;
                    gap: 20px;
                    flex-direction: column;
                    width: 100%;

                    @include breakpoint(tablet) {
                        width: calc(50% - 10px);
                    }
                }
                .three {
                    @include breakpoint(tablet) {
                        height: calc(50% - 10px);
                    }
                }
            }
        }
    }
}