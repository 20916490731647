// TOOLS //


// Container 
.container {
    padding: 0 20px;
    margin: 0 auto;
    position: relative;
    max-width: 1200px;

    &.small {
        max-width: 700px;
    }

    &.medium {
        max-width: 900px;
    }

    &.large {
        max-width: 1200px;
    }
}


// Buttons
.btn {
    background: $c1;
    color: $light;
    border: none;
    padding: 16px 22px;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    transition: .2s ease-in-out;

    &:hover {
        background-color: $c2;
    }

    &.light {
        background: $light;
        color: $c1;

        &:hover {
            background-color: $c2;
            color: $light;
        }
    }
}


// Helper

.small {
    font-size: 16px;
    margin-top: 10px;

    @include breakpoint(tablet) {
        font-size: 20px;
    }

    @include breakpoint(laptop) {
        font-size: 24px;
    }
}

.center {
    text-align: center;
}

.gelb {
    color: $c2 !important;
}

.weiss {
    color: $light !important;
}

.contain {
    object-fit: contain !important;
}