// HEADER //

header {
    transition: all .3s ease-in-out;
    position: relative;

    // .logo {
    //     position: absolute;
    //     margin: 0 auto;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     z-index: 2;
    //     transition: width .3s ease-in-out;
    //     width: 200px;

    //     @include breakpoint(tablet) {
    //         width: 280px;
    //     }

    //     @include breakpoint(desktop) {
    //         width: 360px;
    //     }
    // }
}