
// SUB-PAGES //

.subpage {
    
    h1 {
        margin-bottom: 3rem;

        @include breakpoint(tablet) {
            margin-bottom: 4rem;
        }
        
        @include breakpoint(laptop) {
            margin-top: 2rem;
        }
    }

    main {
        p {
            margin-bottom: 3rem;
        }

        section {
            min-height: calc(100vh - 6rem);
            text-align: right;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-top: 8rem;
            padding-bottom: 3rem;

            @include breakpoint(tablet) {
                padding-top: 13rem;
                padding-bottom: 8rem;
            }
        }
    }
}