#team {
    .team {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-top: 100px;

        .member {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-end;

            @media (min-width: 420px) {
                padding-right: 10%;
            }

            @media (min-width: 740px) {
                width: 50%;
                padding-right: 0;
            }

            @include breakpoint(laptop) {
                width: 33%;
            }

            img {
                -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 50% 100%, 50% 75%, 0% 75%);
                clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 50% 100%, 50% 75%, 0% 75%);
                width: 70%;
                text-align: right;
                height: 280px;
                object-fit: cover;

                @include breakpoint(mobile) {
                    width: 220px;
                }
            }

            p {
                font-size: 22px;
                margin-top: 10px;
                padding-right: 20px;
                margin-bottom: 40px;

                @include breakpoint(mobile) {
                    transform: translateY(-72px);
                    padding-right: 120px;
                    font-size: 20px;
                    margin-bottom: 0; 
                }

                @include breakpoint(laptop) {
                    transform: translateY(-76px);
                    font-size: 18px;
                }

                @include breakpoint(desktop) {
                    font-size: 22px;
                }
            }
        }
    }
}