// SLIDER // 

.header_slider {
    min-height: 600px;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background: $c1;
    width: 100%;

    @include breakpoint(laptop) {
        min-height: 860px;
    }

    &:hover {
        cursor: url("data:image/svg+xml,%3csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='28px' height='28px' viewBox='0 0 371.3 470.6' enable-background='new 0 0 371.3 470.6' xml:space='preserve'%3e%3cpolygon fill='white' points='0%2c347.5 0%2c0 371.3%2c0 371.3%2c347.5 185.7%2c470.6 185.7%2c347.5 0%2c347.5 '/%3e%3c/svg%3e") 16 16, auto;
    }

    .swiper-slide {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $dark;
        background-color: $c1;
        scale: 1;
        transform: scale(1);

        @include breakpoint(laptop) {
            padding-top: 60px;
        }

        &.last {
            text-align: right;

            p {
                color: $light;
                font-size: 20px;
                transition: all .2s ease-in-out;
                font-weight: 400;
                user-select: none;

                @include breakpoint(tablet) {
                    font-size: 28px;
                }

                @include breakpoint(laptop) {
                    font-size: 36px;
                }
            }
        }

        p {
            font-size: 80vw;
            font-weight: 600;
            transition: none;
            display: block;

            @include breakpoint(laptop) {
                font-size: 800px;
            }
        }
    }
}