// FOOTER //

footer {
    background: $c1;

    &:hover {
        cursor: url("data:image/svg+xml,%3csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='28px' height='28px' viewBox='0 0 371.3 470.6' enable-background='new 0 0 371.3 470.6' xml:space='preserve'%3e%3cpolygon fill='white' points='0%2c347.5 0%2c0 371.3%2c0 371.3%2c347.5 185.7%2c470.6 185.7%2c347.5 0%2c347.5 '/%3e%3c/svg%3e") 16 16, auto;
    }

    .nav-footer {
        color: $light;

        ul {
            display: flex;
            justify-content: flex-end;
        }

        li {
            margin-right: 2rem;
            margin: 32px 18px;
            position: relative;
            font-size: 20px;
            transition: all .2s ease-in-out;

            @include breakpoint(tablet) {
                font-size: 24px;
                margin: 42px 24px;
            }

            &:last-child {
                margin-right: 0;

                &:before {
                    content: '|';
                    position: absolute;
                    left: -22px;
                    font-weight: 300;
                    transition: all .2s ease-in-out;

                    @include breakpoint(tablet) {
                        left: -28px;
                    }
                }
            }

            a {
                color: $light;
                margin: 0;
                font-weight: 300;

                &:hover {
                    color: $c2;
                    transition: all .2s ease-in-out;
                    
                }
            }
        }
    }
}