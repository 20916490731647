// NAVIGATION //

$nav-width: 100%;
$nav_bg: $c1;
$nav_color: $light;

.nav__button {
    position: fixed;
    right: 20px;
    top: 20px;
    width: 64px;
    z-index: 30;
    padding: 0;
    transition: all .2s ease-in-out;
    background: none;
    cursor: pointer;

    @include breakpoint(tablet) {
        width: 84px;
    }

    @include breakpoint(laptop) {
        width: 108px;
        right: 40px;
        top: 40px;
    }

    #logo_color {
        fill: $light;
        stroke-width: 0px;
        display: block;
        transition: all .2s ease-in-out;
        cursor: pointer;
    }

    #logo_color_font {
        fill: $c1;
        transition: all .2s ease-in-out;
        cursor: pointer;
        animation: logo_font 4s infinite;
        
    }

    #logo_burger {
        opacity: 0;
        animation: logo_font 4s infinite 2s;
    }

    @keyframes logo_font {
        0%, 40% {
            opacity: 1;
        }
        50%, 100% {
            opacity: 0;
        }
    }

    .subpage & {
        #logo_color {
            fill: $c1;
        }

        #logo_color_font {
            fill: $light;
        }
    }
}

.nav {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100%);
    width: $nav-width;
    position: fixed;
    background: $nav_bg;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    transition: transform .3s ease;

    &:hover {
        cursor: url("data:image/svg+xml,%3csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='28px' height='28px' viewBox='0 0 371.3 470.6' enable-background='new 0 0 371.3 470.6' xml:space='preserve'%3e%3cpolygon fill='white' points='0%2c347.5 0%2c0 371.3%2c0 371.3%2c347.5 185.7%2c470.6 185.7%2c347.5 0%2c347.5 '/%3e%3c/svg%3e") 16 16, auto;
    }

    ul {
        padding-right: calc(64px + 20px);
        transition: all .2s ease-in-out;

        @include breakpoint(tablet) {
            padding-right: calc(84px + 20px);
        }

        @include breakpoint(laptop) {
            padding-right: calc(108px + 40px);
        }
    }
    

    &__list {
        margin-bottom: 60px;
        margin-top: 60px;
        transition: all .2s ease-in-out;

        @include breakpoint(laptop) {
            margin-bottom: 100px;
        }
    }

    &__item {
        margin-bottom: 20px;
        transition: all .2s ease-in-out;

        @include breakpoint(laptop) {
            margin-bottom: 10px;
        }

        & a {
            position: relative;
            font-size: 24px;
            color: $nav_color;

            @include breakpoint(tablet) {
                font-size: 30px;
            }

            @include breakpoint(laptop) {
                font-size: 44px;
            }

            &:hover {
                color: $c2;
            }
        }
    }

    &.nav--open {
        transform: translateY(0);
    }

    .nav_bottom {

        li {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                position: relative;
                color: $nav_color;

                @include breakpoint(laptop) {
                    font-size: 20px;
                }

                &:hover {
                    color: $c2;
                }
            }
        }
    }
}
