/* BREAKPOINT v.1.0 MS */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  line-height: 1.3;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

a {
  text-decoration: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

::selection {
  background-color: rgba(0, 89, 114, 0.9);
  color: white;
}

html {
  scroll-behavior: smooth;
  word-wrap: break-word;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  position: relative;
  color: #1D1D1B;
  font-family: "locator-web", sans-serif;
  line-height: normal;
  box-sizing: border-box;
  cursor: url("data:image/svg+xml,%3csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='28px' height='28px' viewBox='0 0 371.3 470.6' enable-background='new 0 0 371.3 470.6' xml:space='preserve'%3e%3cpolygon fill='%23005972' points='0%2c347.5 0%2c0 371.3%2c0 371.3%2c347.5 185.7%2c470.6 185.7%2c347.5 0%2c347.5 '/%3e%3c/svg%3e") 16 16, auto;
}

main {
  overflow-x: hidden;
}

section {
  position: relative;
}

strong {
  font-weight: 700;
  letter-spacing: 1px;
}

img, video {
  max-width: 100%;
  width: 100%;
  height: auto;
  display: block;
}

iframe {
  width: 100%;
  display: block;
  border: 0;
}

p {
  font-size: 20px;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 668px) {
  p {
    font-size: 28px;
  }
}
@media (min-width: 1024px) {
  p {
    font-size: 36px;
  }
}

a {
  color: #005972;
  font-weight: 600;
  cursor: pointer;
}
section.content a {
  background: #FFE500;
  color: #1D1D1B;
  padding: 0 4px;
  font-weight: inherit;
}
section.content a:hover {
  background: none;
  color: inherit;
}

button {
  cursor: pointer;
  padding: 0;
  color: #005972;
  background: none;
  border: none;
  font-weight: 600;
  font-size: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "locator-web", sans-serif;
  color: #1D1D1B;
  margin-bottom: 20px;
  transition: all 0.2s ease-in-out;
  font-weight: 700;
  position: relative;
}
h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  display: block;
  color: #005972;
}

h1 {
  font-size: 30px;
}
@media (min-width: 440px) {
  h1 {
    font-size: 32px;
  }
}
@media (min-width: 668px) {
  h1 {
    font-size: 44px;
  }
}
@media (min-width: 1024px) {
  h1 {
    font-size: 55px;
  }
}
h1 span {
  font-size: 17px;
}
@media (min-width: 668px) {
  h1 span {
    line-height: 22px;
  }
}
@media (min-width: 1280px) {
  h1 span {
    font-size: 24px;
  }
}
@media (min-width: 1680px) {
  h1 span {
    line-height: 40px;
  }
}
@media (min-width: 1920px) {
  h1 span {
    font-size: 26px;
    line-height: 50px;
  }
}

#impressum h2,
#datatenschutz h2 {
  font-size: 20px;
  transition: all 0.2s ease-in-out;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}
@media (min-width: 668px) {
  #impressum h2,
  #datatenschutz h2 {
    font-size: 28px;
  }
}
@media (min-width: 1024px) {
  #impressum h2,
  #datatenschutz h2 {
    font-size: 36px;
  }
}

.nav__button {
  position: fixed;
  right: 20px;
  top: 20px;
  width: 64px;
  z-index: 30;
  padding: 0;
  transition: all 0.2s ease-in-out;
  background: none;
  cursor: pointer;
}
@media (min-width: 668px) {
  .nav__button {
    width: 84px;
  }
}
@media (min-width: 1024px) {
  .nav__button {
    width: 108px;
    right: 40px;
    top: 40px;
  }
}
.nav__button #logo_color {
  fill: #fff;
  stroke-width: 0px;
  display: block;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.nav__button #logo_color_font {
  fill: #005972;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  animation: logo_font 4s infinite;
}
.nav__button #logo_burger {
  opacity: 0;
  animation: logo_font 4s infinite 2s;
}
@keyframes logo_font {
  0%, 40% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0;
  }
}
.subpage .nav__button #logo_color {
  fill: #005972;
}
.subpage .nav__button #logo_color_font {
  fill: #fff;
}

.nav {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
  width: 100%;
  position: fixed;
  background: #005972;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  transition: transform 0.3s ease;
}
.nav:hover {
  cursor: url("data:image/svg+xml,%3csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='28px' height='28px' viewBox='0 0 371.3 470.6' enable-background='new 0 0 371.3 470.6' xml:space='preserve'%3e%3cpolygon fill='white' points='0%2c347.5 0%2c0 371.3%2c0 371.3%2c347.5 185.7%2c470.6 185.7%2c347.5 0%2c347.5 '/%3e%3c/svg%3e") 16 16, auto;
}
.nav ul {
  padding-right: 84px;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 668px) {
  .nav ul {
    padding-right: 104px;
  }
}
@media (min-width: 1024px) {
  .nav ul {
    padding-right: 148px;
  }
}
.nav__list {
  margin-bottom: 60px;
  margin-top: 60px;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 1024px) {
  .nav__list {
    margin-bottom: 100px;
  }
}
.nav__item {
  margin-bottom: 20px;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 1024px) {
  .nav__item {
    margin-bottom: 10px;
  }
}
.nav__item a {
  position: relative;
  font-size: 24px;
  color: #fff;
}
@media (min-width: 668px) {
  .nav__item a {
    font-size: 30px;
  }
}
@media (min-width: 1024px) {
  .nav__item a {
    font-size: 44px;
  }
}
.nav__item a:hover {
  color: #FFE500;
}
.nav.nav--open {
  transform: translateY(0);
}
.nav .nav_bottom li {
  margin-bottom: 10px;
}
.nav .nav_bottom li:last-child {
  margin-bottom: 0;
}
.nav .nav_bottom li a {
  position: relative;
  color: #fff;
}
@media (min-width: 1024px) {
  .nav .nav_bottom li a {
    font-size: 20px;
  }
}
.nav .nav_bottom li a:hover {
  color: #FFE500;
}

header {
  transition: all 0.3s ease-in-out;
  position: relative;
}

footer {
  background: #005972;
}
footer:hover {
  cursor: url("data:image/svg+xml,%3csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='28px' height='28px' viewBox='0 0 371.3 470.6' enable-background='new 0 0 371.3 470.6' xml:space='preserve'%3e%3cpolygon fill='white' points='0%2c347.5 0%2c0 371.3%2c0 371.3%2c347.5 185.7%2c470.6 185.7%2c347.5 0%2c347.5 '/%3e%3c/svg%3e") 16 16, auto;
}
footer .nav-footer {
  color: #fff;
}
footer .nav-footer ul {
  display: flex;
  justify-content: flex-end;
}
footer .nav-footer li {
  margin-right: 2rem;
  margin: 32px 18px;
  position: relative;
  font-size: 20px;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 668px) {
  footer .nav-footer li {
    font-size: 24px;
    margin: 42px 24px;
  }
}
footer .nav-footer li:last-child {
  margin-right: 0;
}
footer .nav-footer li:last-child:before {
  content: "|";
  position: absolute;
  left: -22px;
  font-weight: 300;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 668px) {
  footer .nav-footer li:last-child:before {
    left: -28px;
  }
}
footer .nav-footer li a {
  color: #fff;
  margin: 0;
  font-weight: 300;
}
footer .nav-footer li a:hover {
  color: #FFE500;
  transition: all 0.2s ease-in-out;
}

.header_slider {
  min-height: 600px;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background: #005972;
  width: 100%;
}
@media (min-width: 1024px) {
  .header_slider {
    min-height: 860px;
  }
}
.header_slider:hover {
  cursor: url("data:image/svg+xml,%3csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='28px' height='28px' viewBox='0 0 371.3 470.6' enable-background='new 0 0 371.3 470.6' xml:space='preserve'%3e%3cpolygon fill='white' points='0%2c347.5 0%2c0 371.3%2c0 371.3%2c347.5 185.7%2c470.6 185.7%2c347.5 0%2c347.5 '/%3e%3c/svg%3e") 16 16, auto;
}
.header_slider .swiper-slide {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1D1D1B;
  background-color: #005972;
  scale: 1;
  transform: scale(1);
}
@media (min-width: 1024px) {
  .header_slider .swiper-slide {
    padding-top: 60px;
  }
}
.header_slider .swiper-slide.last {
  text-align: right;
}
.header_slider .swiper-slide.last p {
  color: #fff;
  font-size: 20px;
  transition: all 0.2s ease-in-out;
  font-weight: 400;
  user-select: none;
}
@media (min-width: 668px) {
  .header_slider .swiper-slide.last p {
    font-size: 28px;
  }
}
@media (min-width: 1024px) {
  .header_slider .swiper-slide.last p {
    font-size: 36px;
  }
}
.header_slider .swiper-slide p {
  font-size: 80vw;
  font-weight: 600;
  transition: none;
  display: block;
}
@media (min-width: 1024px) {
  .header_slider .swiper-slide p {
    font-size: 800px;
  }
}

.container {
  padding: 0 20px;
  margin: 0 auto;
  position: relative;
  max-width: 1200px;
}
.container.small {
  max-width: 700px;
}
.container.medium {
  max-width: 900px;
}
.container.large {
  max-width: 1200px;
}

.btn {
  background: #005972;
  color: #fff;
  border: none;
  padding: 16px 22px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.btn:hover {
  background-color: #FFE500;
}
.btn.light {
  background: #fff;
  color: #005972;
}
.btn.light:hover {
  background-color: #FFE500;
  color: #fff;
}

.small {
  font-size: 16px;
  margin-top: 10px;
}
@media (min-width: 668px) {
  .small {
    font-size: 20px;
  }
}
@media (min-width: 1024px) {
  .small {
    font-size: 24px;
  }
}

.center {
  text-align: center;
}

.gelb {
  color: #FFE500 !important;
}

.weiss {
  color: #fff !important;
}

.contain {
  object-fit: contain !important;
}

#home main section {
  min-height: calc(100vh - 6rem);
  text-align: right;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 8rem;
  padding-bottom: 3rem;
}
@media (min-width: 668px) {
  #home main section {
    padding-top: 11rem;
  }
}
#home main .downloads {
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
  gap: 20px;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 668px) {
  #home main .downloads {
    gap: calc(33% - 220px);
    margin-top: 100px;
  }
}
#home main .downloads a {
  position: relative;
  margin-bottom: 1rem;
  font-weight: inherit;
  color: #1D1D1B;
  text-align: center;
  text-transform: uppercase;
  width: 140px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 17px;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 668px) {
  #home main .downloads a {
    font-size: 26px;
    width: 220px;
    height: 280px;
  }
}
#home main .downloads a:after {
  bottom: 0;
  content: "";
  left: 2px;
  margin: auto;
  position: absolute;
  right: 0;
  top: 42px;
  width: 140px;
  height: 170px;
  z-index: -1;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 668px) {
  #home main .downloads a:after {
    height: 280px;
    width: 220px;
    top: 60px;
    left: 1px;
  }
}
#home main .downloads a:first-child:after {
  content: "";
  background: url("../assets/images/icons/KSW_Gelb_rahmen.svg") no-repeat;
  background-size: contain;
}
#home main .downloads a:first-child:hover:after {
  background: url("../assets/images/icons/KSW_Gelb.svg") no-repeat;
  background-size: contain;
}
#home main .downloads a:last-child:after {
  content: "";
  background: url("../assets/images/icons/KSW_Gelb_rahmen.svg") no-repeat;
  background-size: contain;
}
#home main .downloads a:last-child:hover:after {
  background: url("../assets/images/icons/KSW_Gelb.svg") no-repeat;
  background-size: contain;
}
#home main #referenzen {
  background-color: #005972;
  min-height: 100vh;
  margin-bottom: 6rem;
  margin-top: 3rem;
  padding-bottom: 9rem;
  padding-top: 9rem;
  color: white;
}
@media (min-width: 668px) {
  #home main #referenzen {
    margin-bottom: 6rem;
    margin-top: 6rem;
    padding-bottom: 10rem;
    padding-top: 10rem;
  }
}
@media (min-width: 1024px) {
  #home main #referenzen {
    margin-top: 8rem;
    margin-bottom: 8rem;
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
}
#home main #referenzen:hover {
  cursor: url("data:image/svg+xml,%3csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='28px' height='28px' viewBox='0 0 371.3 470.6' enable-background='new 0 0 371.3 470.6' xml:space='preserve'%3e%3cpolygon fill='white' points='0%2c347.5 0%2c0 371.3%2c0 371.3%2c347.5 185.7%2c470.6 185.7%2c347.5 0%2c347.5 '/%3e%3c/svg%3e") 16 16, auto;
}
#home main #referenzen h1 {
  color: white;
}
#home main #referenzen .partner_gallery {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}
@media (min-width: 1024px) {
  #home main #referenzen .partner_gallery {
    margin-top: 80px;
  }
}
#home main #referenzen .partner_gallery img {
  width: 33%;
  height: 80px;
  padding: 12px;
  display: inline-block;
  object-fit: contain;
}
@media (min-width: 668px) {
  #home main #referenzen .partner_gallery img {
    padding: 20px;
    width: 25%;
    height: 100px;
  }
}
@media (min-width: 1024px) {
  #home main #referenzen .partner_gallery img {
    width: 20%;
    padding: 30px;
    height: 120px;
  }
}
@media (min-width: 668px) {
  #home main #projekte {
    margin-bottom: 10rem !important;
  }
}

.subpage h1 {
  margin-bottom: 3rem;
}
@media (min-width: 668px) {
  .subpage h1 {
    margin-bottom: 4rem;
  }
}
@media (min-width: 1024px) {
  .subpage h1 {
    margin-top: 2rem;
  }
}
.subpage main p {
  margin-bottom: 3rem;
}
.subpage main section {
  min-height: calc(100vh - 6rem);
  text-align: right;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 8rem;
  padding-bottom: 3rem;
}
@media (min-width: 668px) {
  .subpage main section {
    padding-top: 13rem;
    padding-bottom: 8rem;
  }
}

#projekte .projekte {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  transition: all 0.2s ease-in;
}
@media (min-width: 668px) {
  #projekte .projekte {
    justify-content: space-between;
    gap: 10px;
  }
}
@media (min-width: 1024px) {
  #projekte .projekte {
    margin-top: 40px;
  }
}
#projekte .projekte .projekt {
  width: 100%;
  margin-bottom: 30px;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 668px) {
  #projekte .projekte .projekt {
    width: calc(50% - 10px);
  }
  #projekte .projekte .projekt:nth-child(odd) {
    transform: translateY(80px);
  }
}
@media (min-width: 668px) and (min-width: 1024px) {
  #projekte .projekte .projekt:nth-child(odd) {
    transform: translateY(200px);
  }
}
@media (min-width: 1024px) {
  #projekte .projekte .projekt {
    margin-bottom: 40px;
  }
}
@media (min-width: 1024px) {
  #projekte .projekte .projekt {
    width: calc(50% - 10px);
  }
}
@media (min-width: 1280px) {
  #projekte .projekte .projekt {
    width: calc(50% - 20px);
  }
}
#projekte .projekte .projekt .projekt_open {
  cursor: pointer;
}
#projekte .projekte .projekt .projekt_open:hover ~ p button:before {
  left: 8px;
}
#projekte .projekte .projekt img {
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
  width: 100%;
  height: 300px;
  object-fit: cover;
}
@media (min-width: 668px) {
  #projekte .projekte .projekt img {
    margin-bottom: 20px;
    height: 400px;
  }
}
@media (min-width: 1024px) {
  #projekte .projekte .projekt img {
    margin-bottom: 2rem;
    height: 500px;
  }
}
#projekte .projekte .projekt p {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  padding-left: 4px;
}
@media (min-width: 440px) {
  #projekte .projekte .projekt p {
    font-size: 20px;
  }
}
@media (min-width: 668px) {
  #projekte .projekte .projekt p {
    font-size: 18px;
  }
}
@media (min-width: 800px) {
  #projekte .projekte .projekt p {
    font-size: 20px;
  }
}
@media (min-width: 1024px) {
  #projekte .projekte .projekt p {
    font-size: 24px;
  }
}
#projekte .projekte .projekt p button {
  position: relative;
  padding-left: 30px;
}
#projekte .projekte .projekt p button:before {
  content: "";
  width: 16px;
  height: 20px;
  background: url("../assets/images/icons/arrow-right-solid.svg") no-repeat center center;
  background-size: contain;
  position: absolute;
  left: 3px;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: all 0.2s ease-in-out;
}
#projekte .projekte .projekt p button:hover:before {
  left: 8px;
}

#projekt-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 10;
  overflow: scroll;
  padding-top: 160px;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 668px) {
  #projekt-modal {
    padding-top: 200px;
  }
}
@media (min-width: 1024px) {
  #projekt-modal {
    padding-top: 300px;
  }
}
#projekt-modal #projekt_close, #projekt-modal #projekt_close_bottom {
  display: block;
  font-weight: 400;
  font-size: 16px;
  position: absolute;
  z-index: 10;
  border: 2px solid grey;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: grey;
  opacity: 0.6;
}
@media (min-width: 668px) {
  #projekt-modal #projekt_close, #projekt-modal #projekt_close_bottom {
    font-size: 20px;
    width: 40px;
    height: 40px;
  }
}
@media (min-width: 1024px) {
  #projekt-modal #projekt_close, #projekt-modal #projekt_close_bottom {
    font-size: 24px;
  }
}
#projekt-modal #projekt_close:hover, #projekt-modal #projekt_close_bottom:hover {
  opacity: 1;
}
#projekt-modal #projekt_close_bottom {
  bottom: 20px;
  left: 20px;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 1280px) {
  #projekt-modal #projekt_close_bottom {
    left: 0;
  }
}
#projekt-modal .projekt_nav {
  display: flex;
  flex-direction: row-reverse;
  gap: 14px;
  height: 36px;
  transition: all 0.2s ease-in;
}
@media (min-width: 668px) {
  #projekt-modal .projekt_nav {
    height: 40px;
  }
}
#projekt-modal .projekt_nav.bottom {
  bottom: 20px;
}
#projekt-modal .projekt_nav .swiper-button-next,
#projekt-modal .projekt_nav .swiper-button-prev {
  border: 2px solid grey;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  opacity: 0.6;
  margin: 0;
  left: inherit;
  top: inherit;
  right: inherit;
  bottom: inherit;
}
@media (min-width: 668px) {
  #projekt-modal .projekt_nav .swiper-button-next,
  #projekt-modal .projekt_nav .swiper-button-prev {
    width: 40px;
    height: 40px;
  }
}
#projekt-modal .projekt_nav .swiper-button-next.swiper-button-disabled,
#projekt-modal .projekt_nav .swiper-button-prev.swiper-button-disabled {
  opacity: 0.2;
}
#projekt-modal .projekt_nav .swiper-button-next:hover,
#projekt-modal .projekt_nav .swiper-button-prev:hover {
  opacity: 1;
}
#projekt-modal .projekt_nav .swiper-button-next:after,
#projekt-modal .projekt_nav .swiper-button-prev:after {
  color: grey;
  line-height: normal;
  margin-top: -4px;
  font-size: 1.6rem;
}
@media (min-width: 668px) {
  #projekt-modal .projekt_nav .swiper-button-next:after,
  #projekt-modal .projekt_nav .swiper-button-prev:after {
    font-size: 2rem;
    margin-top: -6px;
  }
}
#projekt-modal .projekt_nav .swiper-button-prev::after {
  content: "<";
  margin-right: 2px;
}
#projekt-modal .projekt_nav .swiper-button-next::after {
  content: ">";
  margin-left: 2px;
}
#projekt-modal .projekt_slider {
  width: 100%;
  overflow: hidden;
}
#projekt-modal .projekt_slider .swiper-slide {
  padding: 40px 0;
}
@media (min-width: 668px) {
  #projekt-modal .projekt_slider .swiper-slide {
    padding: 4rem 0;
  }
}
@media (min-width: 1024px) {
  #projekt-modal .projekt_slider .swiper-slide {
    padding: 60px 0 80px;
  }
}
#projekt-modal .projekt_slider .swiper-slide .img_container {
  margin: 3rem 0;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
@media (min-width: 668px) {
  #projekt-modal .projekt_slider .swiper-slide .img_container {
    margin: 4rem 0;
  }
}
@media (min-width: 1024px) {
  #projekt-modal .projekt_slider .swiper-slide .img_container {
    margin: 6rem 0;
  }
}
#projekt-modal .projekt_slider .swiper-slide .img_container img {
  object-fit: cover;
}
@media (min-width: 668px) {
  #projekt-modal .projekt_slider .swiper-slide .img_container .half {
    width: calc(50% - 10px);
  }
}
@media (min-width: 668px) {
  #projekt-modal .projekt_slider .swiper-slide .img_container .third {
    width: calc(42% - 10px);
  }
}
@media (min-width: 668px) {
  #projekt-modal .projekt_slider .swiper-slide .img_container .third_two {
    width: calc(58% - 10px);
  }
}
#projekt-modal .projekt_slider .swiper-slide .img_container .three-cont {
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
}
@media (min-width: 668px) {
  #projekt-modal .projekt_slider .swiper-slide .img_container .three-cont {
    width: calc(50% - 10px);
  }
}
@media (min-width: 668px) {
  #projekt-modal .projekt_slider .swiper-slide .img_container .three {
    height: calc(50% - 10px);
  }
}

#team .team {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 100px;
}
#team .team .member {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
}
@media (min-width: 420px) {
  #team .team .member {
    padding-right: 10%;
  }
}
@media (min-width: 740px) {
  #team .team .member {
    width: 50%;
    padding-right: 0;
  }
}
@media (min-width: 1024px) {
  #team .team .member {
    width: 33%;
  }
}
#team .team .member img {
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 50% 100%, 50% 75%, 0% 75%);
  clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 50% 100%, 50% 75%, 0% 75%);
  width: 70%;
  text-align: right;
  height: 280px;
  object-fit: cover;
}
@media (min-width: 375px) {
  #team .team .member img {
    width: 220px;
  }
}
#team .team .member p {
  font-size: 22px;
  margin-top: 10px;
  padding-right: 20px;
  margin-bottom: 40px;
}
@media (min-width: 375px) {
  #team .team .member p {
    transform: translateY(-72px);
    padding-right: 120px;
    font-size: 20px;
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  #team .team .member p {
    transform: translateY(-76px);
    font-size: 18px;
  }
}
@media (min-width: 1280px) {
  #team .team .member p {
    font-size: 22px;
  }
}