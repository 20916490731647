// GLOBALS //

::selection {
    background-color: rgba($color: $c1, $alpha: .9);
    color: white;
}

html {
    scroll-behavior: smooth;
    word-wrap: break-word;
    box-sizing: border-box;
}
 

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    position: relative;
    color: $dark;
    font-family: $main-font;
    line-height: normal;
    box-sizing: border-box;
    cursor: url("data:image/svg+xml,%3csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='28px' height='28px' viewBox='0 0 371.3 470.6' enable-background='new 0 0 371.3 470.6' xml:space='preserve'%3e%3cpolygon fill='%23005972' points='0%2c347.5 0%2c0 371.3%2c0 371.3%2c347.5 185.7%2c470.6 185.7%2c347.5 0%2c347.5 '/%3e%3c/svg%3e") 16 16, auto;
}

main {
    overflow-x: hidden;
}

section {
    position: relative;
}

strong {
    font-weight: 700;
    letter-spacing: 1px;
}

img, video {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block;
}

iframe {
    width: 100%;
    display: block;
    border: 0;
}

p {
    font-size: 20px;
    transition: all .2s ease-in-out;

    @include breakpoint(tablet) {
        font-size: 28px;
    }

    @include breakpoint(laptop) {
        font-size: 36px;
    }
}

a {
    color: $c1;
    font-weight: 600;
    cursor: pointer;

    section.content & {
        background: $c2;
        color: $dark;
        padding: 0 4px;
        font-weight: inherit;

        &:hover {
            background: none;
            color: inherit;
        }
    }
}

button {
    cursor: pointer;
    padding: 0;
    color: $c1;
    background: none;
    border: none;
    font-weight: 600;
    font-size: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $h-font;
    color: $dark;
    margin-bottom: 20px;
    transition: all .2s ease-in-out;
    font-weight: 700;
    position: relative;

    span {
        display: block;
        color: $c1;
    }
}

h1 {
    font-size: 30px;

    @include breakpoint(mobile_l) {
        font-size: 32px;
    }

    @include breakpoint(tablet) {
        font-size: 44px;
    }

    @include breakpoint(laptop) {
        font-size: 55px;
    }

    span {
        font-size: 17px;

        @include breakpoint(tablet) {
            line-height: 22px;
        }

        @include breakpoint(desktop) {
            font-size: 24px;
        }

        @include breakpoint(desktop-l) {
            line-height: 40px;
        }

        @include breakpoint(fullhd) {
            font-size: 26px;
            line-height: 50px;
        }
    }
}

#impressum,
#datatenschutz {
    h1 {
        
    }
    h2 {
        font-size: 20px;
        transition: all .2s ease-in-out;
        font-weight: 700;
        line-height: normal;
        margin: 0;
    
        @include breakpoint(tablet) {
            font-size: 28px;
        }
    
        @include breakpoint(laptop) {
            font-size: 36px;
        }
    }
}