// HOME //

#home {
    main {
        section {
            min-height: calc(100vh - 6rem);
            text-align: right;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-top: 8rem;
            padding-bottom: 3rem;

            @include breakpoint(tablet) {
                padding-top: 11rem;
            }
        }
    
        .downloads {
            display: flex;
            justify-content: flex-end;
            margin-top: 60px;
            gap: 20px;
            transition: all .2s ease-in-out;

            @include breakpoint(tablet) {
                gap: calc(33% - 220px);
                margin-top: 100px;
            }

            a {
                position: relative;
                margin-bottom: 1rem;
                font-weight: inherit;
                color: $dark;
                text-align: center;
                text-transform: uppercase;
                width: 140px;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                font-size: 17px;
                transition: all .2s ease-in-out;

                @include breakpoint(tablet) {
                    font-size: 26px;
                    width: 220px;
                    height: 280px;
                }

                &:after {
                    bottom: 0;
                    content: "";
                    left: 2px;
                    margin: auto;
                    position: absolute;
                    right: 0;
                    top: 42px;
                    width: 140px;
                    height: 170px;
                    z-index: -1;
                    transition: all .2s ease-in-out;

                    @include breakpoint(tablet) {
                        height: 280px;
                        width: 220px;
                        top: 60px;
                        left: 1px;
                    }
                }
            }

            a:first-child {
                &:after {
                    content: "";
                    background: url("../assets/images/icons/KSW_Gelb_rahmen.svg") no-repeat;
                    background-size: contain;
                }

                &:hover {
                    &:after {
                        background: url("../assets/images/icons/KSW_Gelb.svg") no-repeat;
                        background-size: contain;
                    }
                }
            }

            a:last-child {
                &:after {
                    content: "";
                    background: url("../assets/images/icons/KSW_Gelb_rahmen.svg") no-repeat;
                    background-size: contain;
                }

                &:hover {
                    &:after {
                        background: url("../assets/images/icons/KSW_Gelb.svg") no-repeat;
                        background-size: contain;
                    }
                }
            }

        }

        #referenzen {
            background-color: $c1;
            min-height: 100vh;
            margin-bottom: 6rem;
            margin-top: 3rem;
            padding-bottom: 9rem;
            padding-top: 9rem;
            color: white;

            @include breakpoint(tablet) {
                margin-bottom: 6rem;
                margin-top: 6rem;
                padding-bottom: 10rem;
                padding-top: 10rem;
            }

            @include breakpoint(laptop) {
                margin-top: 8rem;
                margin-bottom: 8rem;
                padding-top: 12rem;
                padding-bottom: 12rem;
            }

            &:hover {
                cursor: url("data:image/svg+xml,%3csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='28px' height='28px' viewBox='0 0 371.3 470.6' enable-background='new 0 0 371.3 470.6' xml:space='preserve'%3e%3cpolygon fill='white' points='0%2c347.5 0%2c0 371.3%2c0 371.3%2c347.5 185.7%2c470.6 185.7%2c347.5 0%2c347.5 '/%3e%3c/svg%3e") 16 16, auto;
            }

            h1 {
                color: white;
            }
        
            .partner_gallery {
                display: flex;
                flex-wrap: wrap;
                margin-top: 40px;

                @include breakpoint(laptop) {
                    margin-top: 80px;
                }
        
                img {
                    width: 33%;
                    height: 80px;
                    padding: 12px;
                    display: inline-block;
                    object-fit: contain;
                    // opacity: .8;
        
                    @include breakpoint(tablet) {
                        padding: 20px;
                        width: 25%;
                        height: 100px;
                    }
        
                    @include breakpoint(laptop) {
                        width: 20%;
                        padding: 30px;
                        height: 120px;
                    }
                }
            }
        }

        #projekte {
            @include breakpoint(tablet) {
                margin-bottom: 10rem !important;
            }
        }
    }
}

